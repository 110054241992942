import type { GetServerSidePropsContext } from 'next';
import { buildMegaMenu, createClient, MenuType } from '../prismicio';
import { SliceZone } from '@prismicio/react';

import { components } from '../slices';
import Hero from '@components/hero';
import Layout from '@components/layout';

const Homepage = ({ page, footer, termlinks, menu }: any) => {
  return (
    <Layout footer={footer} termlinks={termlinks} page={page} menu={menu}>
      <Hero
        page="homepage"
        title={page.data.title}
        backgroundImage={page.data.image}
        backgroundVideo={page.data.hero_video}
        videoCopyright={page.data.hero_video_copyright}
        text={page.data.description}
        button={{
          text: page.data.button_text,
          link: page.data.button_link,
        }}
        extraLink={{
          text: page.data.extra_link_text,
          link: page.data.extra_link,
        }}
      />
      <SliceZone slices={page.data.slices} components={components} />
    </Layout>
  );
};

export default Homepage;

export async function getServerSideProps({
  locale,
  previewData,
}: GetServerSidePropsContext) {
  const client = createClient({ previewData });

  try {
    // Check the page. This will break if page doesn't exists.
    const page = await client.getSingle('homepage', { lang: locale });

    // Do not break if any of this doesn't exist
    const results = await Promise.allSettled([
      client.getSingle('main_menu', { lang: locale }),
      client.getSingle('footer', { lang: locale }),
      client.getSingle('terms_and_conditions', { lang: locale }),
    ]);

    const [main_menu, footer, termlinks] = results.map(result => {
      return result.status === 'fulfilled' ? result.value : []
    });

    let menu: MenuType|Array<any> = [];
    if (results[0].status === 'fulfilled') {
      menu = await buildMegaMenu(main_menu, previewData);
    }

    return {
      props: { page, footer, termlinks, menu },
    };
  } catch {
    // Return 404 if no homepage for locale.
    return {
      notFound: true,
    };
  }
}